/**

  ▒█▀▀█ ▒█▀▀█ ░█▀▀█ ▒█▀▀█ ▒█▀▄▀█ ░█▀▀█ ▒█▀▀█ ▒█░▄▀ ▒█▀▀▀ ▀▀█▀▀
  ▒█▄▄█ ▒█░░░ ▒█▄▄█ ▒█▄▄▀ ▒█▒█▒█ ▒█▄▄█ ▒█▄▄▀ ▒█▀▄░ ▒█▀▀▀ ░▒█░░
  ▒█░░░ ▒█▄▄█ ▒█░▒█ ▒█░▒█ ▒█░░▒█ ▒█░▒█ ▒█░▒█ ▒█░▒█ ▒█▄▄▄ ░▒█░░

  ▒█▀▀█ ▒█▀▀█ ▒█▀▄▀█
  ▒█░░░ ▒█▄▄▀ ▒█▒█▒█
  ▒█▄▄█ ▒█░▒█ ▒█░░▒█

*/

/* Variables */

$pc-black: #444;
$pc-gray: #F2F2F2;
$pc-darkgray: #666;
$pc-white: #FFF;
$pc-red: #F6000C;
$pc-yellow: #FFFBC3;
$pc-green: #CBFFC4;
$pc-blue: #1BA4E9;

$pc-border-color: #DFDFDF;
$pc-transition: 0.25s;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Defaults */

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  overflow-x: hidden;
}

button {
  height: 35px;
  padding: 5px 20px;
  border: 1px solid $pc-border-color;
  cursor: pointer;
  border-radius: 35px;
  font-size: 14px;
  color: $pc-darkgray;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  background-color: $pc-gray;
  white-space: nowrap;

  &.ml {
    margin-left: 10px;
  }

  &.mr {
    margin-right: 10px;
  }

  &:hover {
    color: #000;
  }
}

.constraint {
  width: 1200px;
  margin: auto;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  color: $pc-black;
  font-family: 'Roboto', sans-serif;
}

.dashboard {
  display: flex;
}

input, select, textarea {
  font-family: 'Roboto', sans-serif;
  border: 1px solid $pc-border-color;
  height: 35px;
  width: 100%;
  color: $pc-darkgray;
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  &:focus {
    outline: 0;
    color: #000;
  }
}

textarea {
  min-height: 200px;
  padding-top: 10px;
  max-width: 100%;
}

.p-duplicate {
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

label {
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 10px;
  color: $pc-darkgray;
  white-space: nowrap;

  span {
    color: $pc-red;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
  }
}

.expired {
  color: $pc-red;
}

.inputs {
  display: flex;
  width: 100%;
}

.input-block {
  flex: 1;

  &:not(:last-of-type) {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.image-outline {
  padding: 25px;
  border: 1px solid $pc-border-color;
  font-size: 40px;
  color: #ddd;
  margin-right: 25px;
  width: 150px;
}

/* Sidebar */

.sidebar {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 75px;
  height: 100vh;
  background-color: $pc-gray;
  transition: $pc-transition;
  border-right: 1px solid $pc-border-color;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    width: 200px;

    .block.sidebar-item p {
      opacity: 1;
    }
  }

  .block {
    height: 75px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid $pc-border-color;
    cursor: pointer;
    position: relative;

    &.sidebar-item.profile.logo {
      border-bottom: 1px solid $pc-border-color !important;
      border-top: none;
      cursor: default;

      img {
        border-radius: 0;
      }
    }

    &.sidebar-item {
      padding-left: 25px;

      p {
        white-space: nowrap;
        margin-left: 40px;
        opacity: 0;
        background-color: #3D3D3D;
        padding: 5px 10px;
        color: $pc-white;
        border-radius: 30px;
        font-size: 12px;
        letter-spacing: 0.5px;
        position: absolute;
      }

      &:hover {
        cursor: pointer;

        i {
          opacity: 1;
        }

        p {
          background-color: #000;
        }
      }

      &.ooo.false, &.ccc.true, &.bbb.true, &.sss.true, &.sns.true {
        i {
          color: $pc-red;
        }
      }

      i {
        opacity: 0.75;
        font-size: 25px;
        width: 40px;
        text-align: center;
        margin-left: -7px;

        &.sqr {
          position: absolute;
          top: 20px;
          left: 38px;
          color: $pc-red;
          font-size: 12px;
        }
      }
    }

    &.profile {
      border-top: 1px solid $pc-border-color;
      border-bottom: none;

      p {
        white-space: nowrap;
        margin-left: 40px;
        opacity: 0;
        background-color: #3D3D3D;
        padding: 5px 10px;
        color: $pc-white;
        border-radius: 30px;
        font-size: 12px;
        letter-spacing: 0.5px;
        position: absolute;
      }

      &:hover {
        cursor: pointer;

        p {
          background-color: #000;
        }
      }

      img {
        border-radius: 50%;
        width: 37.5px;
        margin-left: -6px;
      }
    }

    &.user-profile {

      .user-profile-info {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        border: 1px solid $pc-border-color;
      }

      p {
        margin-left: 8px;
        position: relative;
        width: auto;
        display: inline-block;
        margin-bottom: 2px;

        &:first-of-type {
          margin-top: 2px;
        }

        &:last-of-type {
          background-color: transparent;
          color: black;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
          padding-bottom: 0;
          margin-bottom: 0;
          padding-top: 2px;
        }
      }
    }
  }

}

/**
  Main
 */

.main {
  height: 100vh;
  flex: 1;
  padding-left: 75px;

  .content {
    height: calc(100% - 150px);
    width: calc(100% - 150px);
    margin: 74px 0 0 75px;
  }
}

/**
  Overview
 */

.overview-welcome {
  height: 200px;
  width: 100%;
  border: 1px solid $pc-border-color;
  background-color: $pc-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 75px;

  h2 {
    font-size: 50px;
  }
}

.charts {
  width: 100%;
  display: flex;
}

.chart {
  box-sizing: border-box;
  padding: 25px;
  border: 1px solid $pc-border-color;
  background-color: $pc-gray;
  flex: 1;
  width: calc(50% - 37.5px);
  margin-top: 37.5px;
  margin-bottom: 75px;

  &:not(:last-of-type) {
    margin-right: 37.5px;
  }
}

/**
  Customers
 */

.customers {
  display: inline-block;
  padding-bottom: 75px;
  width: 100%;
}

.customer {
  cursor: pointer;
  width: 100%;
  height: 35px;
  background-color: $pc-gray;
  border: 1px solid $pc-border-color;
  display: flex;
  box-sizing: border-box;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: $pc-border-color;
  }

  a {
    color: $pc-red;
  }

  &:not(:last-of-type) {
    border-bottom: none;
  }
}

.customer-labels {
  display: flex;
  font-weight: bold;
  box-sizing: border-box;
  padding: 5px 10px;
  justify-content: space-between;

  p {
    cursor: pointer;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }
}

.c-cat {
  width: 150px;
  padding-right: 20px;
  white-space: nowrap;

  &.email {
    width: 250px;
  }

  &.manage {
    width: 50px;
  }

  &.joined {
    width: 250px;
  }

  &.number {
    width: 100px;
  }
}

.c-cat p {
  text-overflow: ellipsis;
  overflow: hidden;
}

.customers-header {
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    font-weight: normal;
    margin-bottom: 15px;
  }

  input {
    border: 1px solid $pc-border-color;
    padding: 5px 10px;
    font-size: 14px;
    width: 250px;
    height: 35px;
    box-sizing: border-box;

    &:focus {
      outline: 0;
    }
  }
}

input[type=checkbox] {
  height: 20px;
  width: 20px;
  border: none;
  box-shadow: none;
  margin: 0;
}

.customers-filters {
  display: flex;
  align-items: center;
}

.checkbox-block {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  label {
    margin: 0 15px 0 10px;
    cursor: pointer;
  }
}

.checkbox {
  height: 20px;
  width: 20px;
  border: 1px solid $pc-border-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    display: none;
    color: $pc-red;
  }

  &.checked {

    i {
      display: inline;
    }
  }
}

.fade {
  opacity: 0;
  transform: translateY(20px);
  animation: fadein 1s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to   {
    opacity: 1;
    transform: translateY(0);
  }
}

.ctl {
  background-color: $pc-green;
}

.call-logs p {
  margin-bottom: 10px;
}

.close-lead {
  background-color: #F1C2C1;
}

/**
  Edit User
 */

.user-success {
  margin-top: 10px;
  color: #44d471;
}

.user-error {
  margin-top: 10px;
  color: $pc-red;
}

.load-more {
  margin-top: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 50px;
  padding-right: 50px;
}

.edit-user {
  padding-bottom: 75px;

  input[type=submit] {
    cursor: pointer;
    margin-right: 10px;
    width: auto;
    height: 35px;
    padding: 5px 20px;
    border: 1px solid #DFDFDF;
    border-radius: 35px;
    font-size: 14px;
    color: #666;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    white-space: nowrap;

    &:hover {
      color: #000;
    }
  }

  .user-header {
    display: flex;
    justify-content: space-between;
  }

  .user-header-left {
    display: flex;
    align-items: center;
  }

  .user-header-right {
    display: flex;
  }

  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      margin-left: 8px;
    }
  }

  h2 {
    font-weight: normal;
    margin-right: 15px;

    i {
      font-size: 18px;
      margin-right: 5px;
      transform: translateY(-1px);
    }
  }

  .label {
    float: left;
    background-color: $pc-darkgray;
    border: 1px solid $pc-border-color;
    padding: 6px 10px 5px 10px;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: $pc-white;
    font-weight: 500;
    white-space: nowrap;

    &.red {
      background-color: #d44444;
    }

    &.green {
      background-color: #44d471;
    }
  }

  .user-back:hover {
    color: #000;
    cursor: pointer;
  }

  .user-divider {
    height: 1px;
    width: 100%;
    background-color: $pc-border-color;
    margin: 10px 0 25px;
  }

  .user-section {
    margin-top: 50px;

    &.images {
      border: 1px solid $pc-border-color;
      padding: 25px;
    }
  }

  .stats {
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;

    p {
      margin-bottom: 10px;
    }
  }
}

/**
  Login
 */

.input-block input.editable {
  // border-color: black;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .login-inner {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-block {
      margin: 0;

      input {
        width: 500px;
      }
    }

    img {
      height: 50px;
      margin-bottom: 25px;
    }

    button {
      margin-top: 10px;
    }

    input:last-child {
      margin-bottom: 15px;
    }

    .error:last-child {
      margin-bottom: 15px;
    }
  }
}

/**
  Calendar
 */

.pcar-calendar {
  padding-bottom: 75px;
}

.react-calendar__viewContainer {
  padding-bottom: 75px;

  abbr {
    text-decoration: none;
    color: $pc-black;
    margin-left: 5px;
  }

  button {
    position: relative;
    border: 5px solid $pc-white;
    border-radius: 0;
    padding-bottom: 14.2857%;

    &:focus {
      background-color: $pc-darkgray;

      abbr {
        color: $pc-white;
      }
    }

    abbr {
      position: absolute;
      margin: 0;
      top: 10px;
      left: 10px;
      font-size: 18px;
    }
  }
}

.react-calendar__navigation {
  margin-bottom: 50px;
  margin-left: 5px;

  button {
    margin-right: 10px;
  }
}

.duplicates a {
  color: $pc-red;
}

.forgot-password {
  font-size: 14px;
  color: $pc-red;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.form-errors p:last-of-type {
  margin-bottom: 15px;
}

.input-error {
  border-color: $pc-red;
  border-color: $pc-border-color; // TODO: remove temporary hide on error
}

.error {
  color: $pc-red;
  margin-bottom: 5px;
  display: none; // TODO: remove temporary hide on error

  &:first-of-type {
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.new-contact {
  display: inline-block;
  margin-bottom: 10px;

  input, select {
    float: left;
    width: 357px;
    margin-right: 10px;
    color: $pc-black;
  }

  select {
    float: none;
  }
}

p.event {
  padding: 5px;
  background-color: $pc-red;
  font-size: 12px;
  display: inline-block;
  text-align: left;
  border: 1px solid $pc-border-color;
  margin-left: 10px;

  img {
    width: 75px;
    display: block;
    margin-top: 5px;
    border: 1px solid $pc-border-color;
  }

  &.yellow {
    background-color: $pc-yellow;
  }

  &.green {
    background-color: $pc-green;
  }
}

.calendar-left {
  width: calc(100% - 475px);
  margin-right: 75px;
  float: left;
}

.calendar-right {
  width: 400px;
  float: left;
}

.calendar-grid {
  margin-top: 80px;

  .calendar-line {
    width: calc(100% - 50px);
    height: 1px;
    background-color: $pc-border-color;
    position: relative;

    p {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      right: -50px;
      text-align: right;
      font-size: 12px;
      opacity: 0.5;
    }

    &:not(:last-of-type) {
      margin-bottom: 31px;
    }
  }
}

.line-event {
  height: 31px;
  position: absolute;
  top: 1px;
  display: flex;
  align-items: center;
  color: $pc-black;
  padding: 0 10px;
  width: calc(100% - 20px);
  font-size: 14px;

  &.green {
    background-color: $pc-green;
  }
}

/**
  Status
 */

.status-sheet {
  padding: 50px 0;
  box-sizing: border-box;

  h1 {
    margin-bottom: 25px;
  }

  .sheet-item-flex {
    display: flex;
    align-items: center;
  }

  .sheet-item {
    display: flex;
    background-color: $pc-gray;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid $pc-border-color;
    align-items: center;
    justify-content: space-between;

    img {
      height: 40px;
      width: 70px;
      align-self: flex-end;
      object-fit: cover;
      border: 1px solid $pc-border-color;
    }

    &.completed {
      opacity: 0.5;

      p {

        &.date {
          background-color: $pc-green;
        }
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid $pc-black;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {

      &.date {
        background-color: $pc-yellow;
        padding: 5px 10px;
        margin-left: 10px;
        width: 200px;
      }

      &.staff {
        font-weight: bold;
      }

      &.memo {
        opacity: 0.5;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

/**
  Image uploads
 */

.image-outer {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;

  img {
    height: 100px;
  }

  i {
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    font-size: 12px;
    display: flex;
    position: absolute;
    top: -7px;
    right: -7px;
    color: $pc-red;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

/**
  Sourcing
 */

.bulk-actions {
  position: fixed;
  top: -175px;
  background-color: $pc-gray;
  border: 1px solid $pc-border-color;
  margin-top: 100px;
  display: flex;
  align-items: center;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);

  & > * {
    margin: 0 10px;
  }

  i {
    color: $pc-red;
    cursor: pointer;
    font-size: 20px;

    &:hover {
      filter: brightness(90%);
    }
  }
}

.sources {
  display: inline-block;
  padding-bottom: 75px;
  width: 100%;
}

.source {
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: $pc-gray;
  border: 1px solid $pc-border-color;
  display: flex;
  box-sizing: border-box;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;

  &.selectedSource {
    background-color: $pc-green;
  }

  &:hover:not(.selectedSource) {
    background-color: $pc-border-color;
  }

  a {
    color: $pc-red;
  }

  &:not(:last-of-type) {
    border-bottom: none;
  }
}

.source-labels {
  display: flex;
  font-weight: bold;
  box-sizing: border-box;
  padding: 5px 10px;
  justify-content: space-between;
  margin-left: 37px;

  p {
    cursor: pointer;
    display: inline-block;

    &:hover {
      color: #000;
    }
  }
}

.s-cat {
  width: 150px;
  padding-right: 20px;
  white-space: nowrap;

  &.type {
    width: 100px;
  }

  &.date {
    width: 200px;
  }

  &.title {
    width: 250px;
  }

  &.image {
    width: 50px;

    img {
      border: 1px solid $pc-border-color;
      height: 40px;
      width: 70px;
      object-fit: cover;
      box-sizing: border-box;
      margin-top: 3px;
    }
  }
}

.converted-a {
  color: $pc-red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.s-cat p {
  text-overflow: ellipsis;
  overflow: hidden;
}

.temp {
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;

  &.hot {
    background-color: red;
  }

  &.warm {
    background-color: orange;
  }

  &.cold {
    background-color: lightblue;
  }
}

.prospect {
  display: flex;
  align-items: center;
  user-select: none;
  margin-top: -1px;

  .checkbox {
    margin-right: 15px;
  }
}

/**
  Responsiveness
 */

@media screen and (max-width: 1175px) {
  .user-header {
    flex-direction: column;

    h2 {
      margin-bottom: 25px;
    }
  }
  .label {
    margin-bottom: 15px;
  }
  .edit-user .user-header-left {
    flex-direction: column;
    margin: 10px 0;
    align-items: flex-start;
  }
  .main .content {
    margin: 37px 0 0 37.5px;
    height: calc(100% - 75px);
    width: calc(100% - 75px);
  }
  .charts {
    flex-direction: column;

    .chart {
      margin-right: 0;
      width: 100%;
    }
  }
  .overview-welcome {
    padding: 37.5px;
  }
  .calendar-left {
    width: 100%;
  }
  .calendar-right {
    padding-bottom: 37.5px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .login .login-inner .input-block {
    width: 90%;
    margin-left: 5%;
  }
  .login .login-inner .input-block input, .login-inner {
    width: 95% !important;
  }
  .inputs {
    flex-direction: column;

    .input-block {
      margin-right: 0;

      input {
        width: 100%;
      }
    }
  }
  .overview-welcome {
    height: auto;

    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 20px;
      margin-top: 5px;
    }
  }
  .react-calendar__navigation {
    display: flex;
  }
}